import React, { Fragment, useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';

export default () => {
  useEffect(() => {
    Cookies.set('view_demo_videos_cfsnjs', true);
    window.open('/demoVideos', '_self');
  }, []);
  return '';
};
